



import { Component, Vue } from 'vue-property-decorator'
import { loadMicroApp } from 'qiankun'
import { RouteMeta } from 'vue-router/types/router'
import { micorApps } from '@/utils/tools'
@Component({
  name: 'houseBoard'
})
export default class extends Vue {
  mountMicroApp: null | { unmount: Function } = null
  micorApp = micorApps[0]
  created() {
    this.micorApp.props.routerBase = `/amc${
      (this.$route.meta as RouteMeta).routerBase
    }`
  }

  mounted() {
    if (this.$refs.houseBoard) {
      try {
        this.mountMicroApp = loadMicroApp(this.micorApp)
      } catch (e) {
        console.log('load houseBoard err', e)
      }
    }
  }

  beforeDestroy() {
    if (this.mountMicroApp) {
      this.mountMicroApp.unmount()
    }
  }
}
